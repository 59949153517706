import { Book } from '../book/book';

export enum StoryState {
    Active = 'active',
    Live = 'live',
}

export type Story = {
    id: number,
    title: string
    uuid: string,
    internal_uuid: string,
    author?: string,
    summary: string,
    genreId: number,
    chapter: number,
    version: string,
    isTemplate: boolean,
    createdAt: string,
    updatedAt: string,
    bookId: number,
    contestWinner: boolean,
    originalStoryId?: number,
    deleted: boolean,
    public: boolean,
    revision: number,
    fromTemplate: boolean,
    coverLocationId?: number,
    coverCharacterId?: number,
    coverCharacterExpressionId?: number,
    displaySettings: unknown,
    recommendations: unknown,
    community_share: boolean,
    live_count: number,
    book: Book,
    usedAsTemplate: number,
    group: string,
    analytics: unknown,
    tags: string[],
    story_role: string,
    users: (string | number)[],
    requiredAchievement?: number,
    isBonus?: boolean,
}
