import React from 'react';
import {
  Col, FormControl, FormGroup, FormLabel,
} from 'react-bootstrap';
import { useAchievementsContext } from '../../../../../contexts/AchievementsContext/AchievementsContext';

type RequiredAchievementFormGroupProps = {
  value: number,
  onChange: (value: string) => void
  disabled?: boolean
}

export function RequiredAchievementFormGroup(props: RequiredAchievementFormGroupProps) {
  const { value, onChange, disabled } = props;

  const { achievements } = useAchievementsContext();

  return (
    <FormGroup as={Col} controlId="requiredAchievement">
      <FormLabel>Required Achievement</FormLabel>
      <FormControl
        as="select"
        name="requiredAchievement"
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">None</option>
        {achievements.map((a) => (
          <option key={a.id} value={a.id}>{a.name}</option>
        ))}
      </FormControl>

    </FormGroup>
  );
}
