import React from 'react';
import { Col, FormCheck, FormGroup } from 'react-bootstrap';
import cs from './RequiredAchievementFormGroup.module.scss';

type RequiredAchievementFormGroupProps = {
  value: boolean,
  onChange: (value: boolean) => void
  disabled?: boolean
}

export function BonusFormGroup(props: RequiredAchievementFormGroupProps) {
  const { value, onChange, disabled } = props;

  return (
    <FormGroup as={Col} controlId="isBonus" className={cs.formGroup}>
      <FormCheck
        custom
        name="isBonus"
        disabled={disabled}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        label="Is Bonus"
      />

    </FormGroup>
  );
}
