import async from 'async';
import React, { Component } from 'react';
import {
  Alert, Badge, Card, Form,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { isMemoryBankEnabled } from 'components/utils/isMemoryBankEnabled';
import {
  AchievementsContextProvider,
} from '../../../contexts/AchievementsContext/AchievementsContext';
import { BookTypes } from '../../../dorian-shared/types/book/book';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { PremiumIpDisabledApprovedEdit } from '../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../utils/premiumIpDisabledEdit';
import { AchievementsModal } from '../AchievementsModal/AchievementsModal';
import { AddBook } from '../Books/AddBook';
import { Characters } from '../Characters';
import { Locations } from '../Locations';
import { AddStories } from '../Stories/AddStories';
import { Export } from '../Stories/Export';
import { ImportStories } from '../Stories/ImportStories';
import { Share } from '../Stories/Share';
import { fetchBookById } from './bookAPI';
import { BookCover } from './BookCover';
import { ChapterGroups } from './ChapterGroups';
import { DeleteChapter } from './DeleteChapter';
import { ExclusivityModal } from './ExclusivityModal/ExclusivityModal';
import { MemoryBankModal } from './MemoryBank/MemoryBankModal';
import { PromptFeaturing } from './PromptFeaturing';
import { SaveAsPrompt } from './SaveAsPrompt';
import { UnDeleteChapter } from './UnDeleteChapter';
import { getBookType } from './utils';

const SortableItem = SortableElement(({
  object, i, disabledRow, showIndex, ...props
}) => (
  <ChapterGroups
    {...props}
    key={i}
    obj={object}
  />
));

const SortableList = SortableContainer(
  (
    {
      items,
      disabledRow,
      deleteChapter,
      unDeleteChapter,
      edit,
      dragBook,
      groupHover,
      loading,
      modeEdit,
      ...props
    },
  ) => (
    <div>
      {
            items.map((value, index) => (
              <SortableItem
                modeEdit={modeEdit}
                key={value.id}
                index={index}
                object={value}
                disabledRow={disabledRow}
                disabled={disabledRow}
                deleteChapter={deleteChapter}
                unDeleteChapter={unDeleteChapter}
                edit={edit}
                dragBook={dragBook}
                groupHover={groupHover}
                loading={loading}
                i={value.id}
                {...props}
              />
            ))
          }
    </div>
  ),
);

export class Book extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabledRow: true,
      editBook: false,
      deleteChapter: null,
      unDeleteChapter: null,
      book: {},
      groups: [],
      bookGroups: [],
      groupHover: null,
      dragBook: [],
      limits: [],
      uploadChapter: false,
      storyOverwrite: null,
      editChapterData: null,
      addChapterActive: false,
      editShare: {},
      editShareActive: false,
      showCharacters: false,
      showLocations: false,
      showImagePath: false,
      exportLoading: false,
      showBookCover: false,
      editShareType: null,
      savePromptModal: false,
      savePromptLoading: false,
      modeEdit: false,
      modeAdminStyle: 'd-none',
      modeEditStyle: 'd-none',
      showPromptFeaturing: false,
      isMemoryBankVisible: false,
      showExclusivityModal: false,
      showAchievementsModal: false,
    };

    const { auth } = this.props;
    this.user = auth.getUser();
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { context } = this.props;
    const { memoryBankContext, memoryIconsContext } = context;

    const { book } = this.state;

    if (prevState.book.book_role !== book.book_role) {
      let modeEdit = true;
      let modeEditStyle = 'd-none';

      if (this.user.role !== 'admin' && book.book_role === 'viewer') {
        modeEdit = false;
        modeEditStyle = 'd-none';
      }

      if (this.user.role === 'admin' && book.book_role !== 'owner') {
        modeEdit = localStorage.getItem('modeEdit') !== null
          ? !JSON.parse(localStorage.getItem('modeEdit'))
          : false;
        modeEditStyle = 'd-block';
      }

      this.setState({
        modeEdit,
        modeEditStyle,
        modeAdminStyle: this.user.role === 'admin' ? 'd-block' : 'd-none',
      });

      localStorage.setItem(`modeEdit-${book.uuid}`, !modeEdit);
    }

    if (prevState.book?.id !== book?.id) {
      memoryBankContext.setBookId(book.id);
      memoryIconsContext.setBookId(book.id);
    }
  }

  getEpisodeGroupsByBook(book, groups) {
    const bookType = getBookType(book);

    if (bookType === BookTypes.Prompt) {
      return groups.filter((el) => ['prompt', 'liveprompt'].includes(el.type));
    }

    if (bookType === BookTypes.Original) {
      groups.filter((el) => !['archived', 'completed', 'prompt', 'liveprompt'].includes(el.type));
    }
    return groups.filter((el) => ['active', 'live'].includes(el.type));
  }

  loadData = () => {
    this.setState({ loading: true });
    const { match } = this.props;

    async.parallel({
      settings: (callback) => {
        api.get('/v1/settings')
          .then((res) => {
            callback(null, res.data.settings);
          }).catch((error) => {
            callback(error, null);
          });
      },
      groups: (callback) => {
        api.get('/v1/groups/chapters')
          .then((res) => {
            callback(null, res.data.groups);
          }).catch((error) => {
            callback(error, null);
          });
      },
      bookGroups: (callback) => {
        api.get('/v1/groups/books')
          .then((res) => {
            callback(null, res.data.groups);
          }).catch((error) => {
            callback(error, null);
          });
      },
      book: (callback) => {
        fetchBookById(match.params.id)
          .then((book) => {
            callback(null, book);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({ loading: false });
        } else {
          const groups = this.getEpisodeGroupsByBook(res.book, res.groups);

          this.setState({
            book: res.book,
            groups,
            bookGroups: res.bookGroups,
            limits: res.settings.limits,
            showImagePath: res.settings.preferences.show_character_image_path.value === 'on',
            loading: false,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  initEditChapter = (obj) => {
    let editChapterData = null;
    if (obj) {
      editChapterData = obj;
    }
    this.setState({
      editChapterData,
      addChapterActive: true,
    });
  };

  cancelEditChapter = (data) => {
    this.setState({
      editChapterData: null,
      addChapterActive: false,
      uploadChapter: !!data,
      storyOverwrite: data,
    });
  };

  deleteChapter = (obj) => {
    this.setState({
      deleteChapter: obj,
    });
  };

  unDeleteChapter = (obj) => {
    this.setState({
      unDeleteChapter: obj,
    });
  };

  dragBook = (action, obj) => {
    this.setState({
      dragBook: action === 'start' ? obj : [],
    });
    const { groupHover: groupHover1 } = this.state;
    const { match } = this.props;
    if (action === 'stop' && obj.groupId !== groupHover1 && groupHover1 !== null) {
      const value = { groupId: groupHover1 };

      api.put(`/v1/books/${match.params.id}/chapters/${obj.id}`, value)
        .then(() => {
          this.loadData();
        });
    }
  };

  groupHover = (obj, id) => {
    this.setState({
      groupHover: id,
    });
  };

  initUpload = () => {
    this.setState({
      uploadChapter: true,
    });
  };

  cancelUpload = () => {
    this.setState({
      uploadChapter: false,
      storyOverwrite: null,
    });
  };

  actionShare = (obj, type) => {
    this.setState({
      editShare: obj,
      editShareActive: true,
      editShareType: type || 'story',
    });
  };

  closeShare = () => {
    this.setState({
      editShare: {},
      editShareActive: false,
    });
    this.loadData();
  };

  // eslint-disable-next-line react/sort-comp
  storiesShare() {
    const {
      editShareActive,
      modeEdit,
      editShareType,
      editShare,
      book,
    } = this.state;

    const { auth } = this.props;

    return (
      <Share
        type={editShareType || 'story'}
        obj={editShare}
        show={editShareActive}
        onHide={this.closeShare}
        user={auth.getUser()}
        disabled={
          !modeEdit
          || PremiumIpDisabledEdit(this.user.role, book)
          || PremiumIpDisabledApprovedEdit(this.user.role, book)
        }
      />
    );
  }

  initCharacters() {
    this.setState({
      showCharacters: true,
    });
  }

  initLocations() {
    this.setState({
      showLocations: true,
    });
  }

  initBookCover() {
    this.setState({
      showBookCover: true,
    });
  }

  closeBookCover = () => {
    this.setState({
      showBookCover: false,
    });
    this.loadData();
  };

  editBook = () => {
    this.setState({
      editBook: true,
    });
  };

  getEconomicsTitle = () => (
    <span>
      Economy
      <span className="boxBadge">
        <Badge pill variant="danger">
          Alpha
        </Badge>
      </span>
    </span>
  );

  render() {
    const {
      match,
      auth,
      context,
    } = this.props;

    const { memoryBankContext, memoryIconsContext } = context;
    const user = auth.getUser();

    const {
      bookGroups,
      modeEditStyle,
      showLocations,
      limits,
      uploadChapter: uploadChapter1,
      book,
      storyOverwrite,
      deleteChapter: deleteChapter1,
      loading,
      unDeleteChapter: unDeleteChapter1,
      groups,
      showBookCover,
      dragBook: dragBook1,
      saveTemplateLoading,
      addChapterActive,
      editChapterData,
      savePromptLoading,
      exportLoading,
      disabledRow,
      showPromptFeaturing,
      showCharacters,
      showImagePath,
      savePromptModal,
      template,
      modeEdit,
      modeAdminStyle,
      editBook: editBook1,
      isMemoryBankVisible,
      showExclusivityModal,
      showAchievementsModal,
    } = this.state;

    if (book && user.role !== 'admin' && ('book_role' in book) && book.book_role === 'none') {
      return <Redirect to="/books" />;
    }

    const exclusivitySideMenuButton = () => {
      const hasAccessToExclusivity = book.book_role === 'owner' || book.book_role === 'co-author';
      if (hasAccessToExclusivity && book.exclusiveAccess && book.isPrompt) {
        return {
          title: 'Exclusivity',
          action: () => this.setState({ showExclusivityModal: true }),
          loading: savePromptLoading,
          variant: 'primary',
        };
      }
      return {};
    };

    const page = {
      header: {
        title: `Episodes for a Story: ${book.title || ''}`,
        type: 'episodes',
        settings: user.role === 'admin' ? 'admin' : null,
      },
      sidebar: {
        nav: [
          {
            title: 'Back to Stories List',
            action: '',
            href: '/books',
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Create Episode',
            action: () => this.initEditChapter(),
            variant: 'primary',
            disabled:
              loading
              || (
                this.user.role !== 'admin'
                && book.book_role !== 'owner'
                && book.book_role !== 'editor'
                && book.book_role !== 'co-author'
              )
              || PremiumIpDisabledEdit(this.user.role, book)
              || PremiumIpDisabledApprovedEdit(this.user.role, book),
            activeWizardStep: 4,
          },
          {
            title: 'Import Episode',
            action: () => this.initUpload(),
            variant: 'primary',
            disabled:
              loading
              || (
                this.user.role !== 'admin'
                && book.book_role !== 'owner'
                && book.book_role !== 'editor'
                && book.book_role !== 'co-author'),
            NotAdminPermissions: !this.user || this.user.role !== 'admin',
          },
          {
            variant: 'space',
          },
          {
            title: 'Story Settings',
            action: () => this.editBook(),
            variant: 'primary',
            disabled:
              loading
              || (
                this.user.role !== 'admin'
                && book.book_role !== 'owner'),
            NotAdminPermissions: !this.user,
          },
          {
            title: 'Collaborators',
            action: () => this.actionShare(book, 'book'),
            variant: 'primary',
            disabled:
              loading
              || (
                this.user.role !== 'admin'
                && !['owner', 'co-author', 'editor'].includes(book.book_role))
            ,
          },
          {
            title: 'Characters',
            action: () => this.initCharacters(),
            variant: 'primary',
            disabled: loading,
            activeWizardStep: 6,
          },
          {
            title: 'Locations',
            action: () => this.initLocations(),
            variant: 'primary',
            disabled: loading,
          },
          {
            title: 'Cover Art',
            action: () => this.initBookCover(),
            variant: 'primary',
            disabled:
              loading
              || (
                this.user.role !== 'admin'
                && book.book_role !== 'owner'
                && book.book_role !== 'editor'
                && book.book_role !== 'co-author')
            ,
          },
          {
            title: 'Memory Bank',
            action: () => {
              this.setState({
                isMemoryBankVisible: true,
              });
            },
            variant: 'primary',
            disabled: loading,
            NotAdminPermissions: !isMemoryBankEnabled(),
          },
          {
            title: 'Achievements',
            action: () => {
              this.setState({
                showAchievementsModal: true,
              });
            },
            variant: 'primary',
            disabled: loading,
            NotAdminPermissions: !isMemoryBankEnabled(),
          },
          {
            variant: 'space',
          },
          {
            title: this.getEconomicsTitle(),
            href: `/book/${book.id}/economy`,
            variant: 'primary',
            NotAdminPermissions: !isMemoryBankEnabled(this.user),
            disabled: loading,
          },
          {
            variant: 'space',
            NotAdminPermissions: !isMemoryBankEnabled(this.user),
          },
          {
            title: 'Export Story & Characters',
            action: () => {
              this.setState({
                exportLoading: true,
              }, () => {
                Export.exportBook(match.params.id, book.title)
                  .then(
                    () => {
                      this.setState({
                        exportLoading: false,
                      });
                    },
                    () => {
                      this.setState({
                        exportLoading: false,
                      });
                    },
                  );
              });
            },
            variant: 'primary',
            disabled: loading || exportLoading || (this.user.role !== 'admin' && book.book_role !== 'owner'),
            NotAdminPermissions: !this.user || this.user.role !== 'admin',
          },
          {
            title: 'Make a Prompt',
            action: () => {
              this.setState({
                savePromptModal: true,
              });
            },
            loading: savePromptLoading,
            variant: book.group === 'active' ? 'success' : 'secondary',
            disabled:
              (book.group !== 'active')
              || saveTemplateLoading
              || (user.role !== 'admin' && book.book_role !== 'owner')
              || book.sourceBookId
              || book.promptId
              || (book.prompts_count > 0)
            ,
            // NotAdminPermissions: (!this.state.authUser || this.state.authUser.role !== "admin"),
          },
          {
            title: 'Prompt Featuring',
            variant: 'primary',
            disabled: !book.isPrompt,
            action: () => {
              this.setState({
                showPromptFeaturing: true,
              });
            },
            NotAdminPermissions: !this.user || this.user.role !== 'admin' || !book.isPrompt,
          },
          exclusivitySideMenuButton(),
        ],
      },
    };

    const promptFeaturing = () => showPromptFeaturing
        && (
        <PromptFeaturing
          id={book.id}
          show
          onHide={() => {
            this.setState({
              showPromptFeaturing: false,
            });
          }}
        />
        );

    const addBook = () => !!editBook1
        && (
        <AddBook
          show
          limits={limits}
          user={this.user}
          onHide={() => {
            this.setState({
              template: null,
              editBook: false,
            });
          }}
          book={book}
          disabled={
            !modeEdit
            || PremiumIpDisabledEdit(this.user.role, book)
            || PremiumIpDisabledApprovedEdit(this.user.role, book)
          }
          items={bookGroups} /* this.state.groups */
          template={template}
          update={() => {
            this.loadData();
            this.setState({
              editBook: false,
            });
          }}
        />
        );

    const addChapter = () => {
      if (addChapterActive === true) {
        return (
          <AddStories
            bookID={match.params.id}
            data={editChapterData}
            show={addChapterActive}
            disabled={
              !modeEdit
              || PremiumIpDisabledEdit(this.user.role, book)
              || PremiumIpDisabledApprovedEdit(this.user.role, book)
            }
            onHide={this.cancelEditChapter}
            update={() => {
              this.loadData();
              this.cancelEditChapter();
            }}
            limits={limits}
            user={user}
          />

        );
      }
      return null;
    };

    const deleteChapter = () => {
      if (deleteChapter1) {
        return (
          <DeleteChapter
            {...this.props}
            show
            disabled={!modeEdit}
            onHide={() => {
              this.setState({
                deleteChapter: null,
              });
            }}
            obj={deleteChapter1}
            update={() => {
              this.setState({
                deleteChapter: null,
              }, () => {
                this.loadData();
              });
            }}
          />
        );
      }
      return null;
    };

    const unDeleteChapter = () => {
      if (unDeleteChapter1) {
        return (
          <UnDeleteChapter
            {...this.props}
            show
            disabled={!modeEdit}
            onHide={() => {
              this.setState({
                unDeleteChapter: null,
              });
            }}
            obj={unDeleteChapter1}
            update={() => {
              this.setState({
                unDeleteChapter: null,
              }, () => {
                this.loadData();
              });
            }}
          />
        );
      }
      return null;
    };

    const uploadChapter = () => {
      if (uploadChapter1 === true) {
        return (
          <ImportStories
            data={editChapterData}
            show={uploadChapter1}
            storyOverwrite={storyOverwrite}
            bookID={Number(match.params.id)}
            disabled={
              !modeEdit
              || PremiumIpDisabledEdit(this.user.role, book)
              || PremiumIpDisabledApprovedEdit(this.user.role, book)
            }
            onHide={this.cancelUpload}
            update={() => {
              this.cancelUpload();
              this.loadData();
            }}
            limits={limits}
          />
        );
      }
      return null;
    };

    const listCharacters = () => {
      if (showCharacters === true) {
        return (
          <Characters
            user={this.user}
            book={book}
            id={match.params.id}
            title={book.title}
            show={showCharacters}
            onHide={() => {
              this.setState({
                showCharacters: false,
              });
            }}
            disabled={
              (
                this.user.role !== 'admin'
                && book.book_role !== 'owner'
                && book.book_role !== 'editor'
                && book.book_role !== 'co-author'
              )
              || !modeEdit
            }
            limits={limits}
            showImagePath={showImagePath}
            disabledEdit={
              PremiumIpDisabledEdit(this.user.role, book)
              || PremiumIpDisabledApprovedEdit(this.user.role, book)
            }
            context={context}
          />
        );
      }
      return false;
    };

    const listLocations = () => {
      if (showLocations === true) {
        return (
          <Locations
            user={this.user}
            book={book}
            bookId={book.id}
            bookTitle={book.title}
            show={showLocations}
            onHide={() => {
              this.setState({
                showLocations: false,
              });
            }}
            disabled={
              (
                this.user.role !== 'admin'
                && book.book_role !== 'owner'
                && book.book_role !== 'editor'
                && book.book_role !== 'co-author'
              )
              || !modeEdit
            }
            limits={limits}
            disabledEdit={
              PremiumIpDisabledEdit(this.user.role, book)
              || PremiumIpDisabledApprovedEdit(this.user.role, book)
            }
            context={context}
          />
        );
      }
      return false;
    };

    const renderBookCover = () => {
      if (showBookCover === true) {
        return (
          <BookCover
            user={this.user}
            book={book}
            show={showBookCover}
            onHide={() => {
              this.closeBookCover();
            }}
            update={() => {
              this.loadData();
            }}
            disabled={
              (
                this.user.role !== 'admin'
                && book.book_role !== 'owner'
                && book.book_role !== 'editor'
                && book.book_role !== 'co-author'
              )
              || (this.user.role !== 'admin' && PremiumIpDisabledEdit(this.user, book))
              || (this.user.role !== 'admin' && PremiumIpDisabledApprovedEdit(this.user.role, book))
              || !modeEdit
            }
          />
        );
      }
      return null;
    };

    return (
      <AchievementsContextProvider bookId={book.id}>
        <PageWrapper
          {...this.props}
          page={page}
        >
          <Card>
            <Card.Body>
              <SortableList
                {...this.props}
                modeEdit={modeEdit}
                onSortEnd={this.onSortEnd}
                helperClass="sortItem"
                distance={1}
                lockAxis="y"
                disabledRow={
                  disabledRow
                  || PremiumIpDisabledEdit(this.user.role, book)
                  || PremiumIpDisabledApprovedEdit(this.user.role, book)
                }
                items={groups}
                book={book}
                deleteChapter={this.deleteChapter}
                unDeleteChapter={this.unDeleteChapter}
                edit={this.initEditChapter}
                dragBookItem={dragBook1}
                dragBook={this.dragBook}
                groupHover={this.groupHover}
                loading={loading}
                user={this.user}
                share={this.actionShare}
                update={() => {
                  this.loadData();
                }}
              />
            </Card.Body>
          </Card>

          <div
            className={`modeAlert ${modeAdminStyle}`}
          >
            <Alert
              className={`${modeEditStyle}`}
              variant={modeEdit ? 'danger' : 'info'}
            >
              <Form.Check
                custom
                type="checkbox"
                id="NodeEditMode"
                label={modeEdit ? 'Episodes Edit Mode' : 'Episodes Read-Only Mode'}
                checked={modeEdit}
                onChange={() => {
                  localStorage.setItem(`modeEdit-${book.uuid}`, modeEdit);
                  localStorage.setItem('modeEdit', modeEdit);
                  this.setState({
                    modeEdit: !modeEdit,
                  });
                }}
              />
            </Alert>
          </div>

        </PageWrapper>

        {this.storiesShare()}
        {addBook()}
        {addChapter()}
        {uploadChapter()}
        {deleteChapter()}
        {unDeleteChapter()}
        {listCharacters()}
        {listLocations()}
        {renderBookCover()}
        {isMemoryBankVisible
          && (
          <MemoryBankModal
            bookId={book.id}
            bookUsers={book.users}
            onHide={() => {
              this.setState({
                isMemoryBankVisible: false,
              });
              memoryBankContext.fetchMemories();
              memoryIconsContext.fetchMemoryIcons();
            }}
          />
          )}
        {promptFeaturing()}

        <SaveAsPrompt
          limits={limits}
          show={savePromptModal}
          book={book}
          onSuccess={() => {
            this.loadData();
          }}
          onValidationError={() => {
            this.setState({
              savePromptModal: false,
            });
          }}
          onHide={() => {
            this.setState({
              savePromptModal: false,
            });
            this.loadData();
          }}
        />

        <ExclusivityModal
          show={showExclusivityModal}
          onConfirm={() => {
            this.loadData();
            this.setState({ showExclusivityModal: false });
          }}
          onCancel={() => this.setState({ showExclusivityModal: false })}
          bookId={book.id}
        />

        {showAchievementsModal && (
        <AchievementsModal
          onHide={() => {
            this.setState({
              showAchievementsModal: false,
            });
          }}
          bookId={book.id}
        />
        )}
      </AchievementsContextProvider>
    );
  }
}
